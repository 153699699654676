.email_div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 84px);
}
.Tittle{
    color: #21A2DB;
}
.form_ele{
    width: 325px;
    display: flex;
    flex-direction: column;
}
.form_ele .error{
    color: red;
    font-size: 10px;
    font-weight: 500;
}
.form_ele .succes{
    color:green;
    font-size: 10px;
    font-weight: 500;
}
.form_ele input{
    padding: 10px;
    border-radius: 10px;
    border: 1px solid gray;
    margin-bottom: 10px;
    outline: none;
}
.form_ele input:focus{
    border: 1px solid #21A2DB;
}
.container_ele{
    height: 350px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 500px;
}
.btn-ele{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 15px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: none;
    background-color: #F9C623;
    color: white;
    cursor: pointer;
}
.form_ele .css-18lrjg1-MuiCircularProgress-root {
    color: white;
}