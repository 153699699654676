html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  ._2nye ._5hdf{
    margin: 1px 0px;
  }

  ._2nye ._5vzd{
    color: red;
    font-size: 10px !important;
    font-weight: 700 !important;
  }
  

  ._2nye .need-help-main{
    font-family: Objectivity;
  }
  ._2nye .submit{
    background-color: #F9C623 !important; 
    margin-bottom: 30px !important;
    color: black !important;
    text-transform: none !important;
    margin: 24px 0 16px !important;
    box-shadow: none !important;
  }
  ._2nye .custom-username-textfield .MuiOutlinedInput-root.Mui-focused {
    background-color: white;
  }
  ._2nye .custom-textfield .MuiOutlinedInput-root.Mui-focused {
    background-color: white;
  }
  ._2nye .google{
    background-color: 
    #F2F2F2 !important;
    color: black !important;
    box-shadow: none !important;
    border: 1px solid 
    #2C79FF !important;
    text-transform: none !important;
  }
  
  ._2nye .google:hover{
    background-color: 
    #F2F2F2 !important;
  }
  ._2nye .loginlogin{
    font-weight: bolder !important;
  }
  ._2nye .loggedin button{
    font-weight: bolder;
    border: none;
    color: #000;
    text-transform: none;
  }
  ._2nye .google-icon {
    width: 20px; 
    height: 20px;
    margin-right: 8px; 
  }
  ._2nye .custom-textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #15101F;
  }
  
  ._2nye .custom-textfield .MuiInputLabel-root.Mui-focused {
    color: #15101F;
  }
  
  ._2nye .custom-username-textfield .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
  ._2nye .custom-textfield .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
  ._2nye .custom-username-textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #15101F;
  }
  ._2nye .custom-username-textfield .MuiInputLabel-root.Mui-focused {
    color: #15101F;
  }
  .custom-username-textfield .MuiIconButton-root:focus,
  ._2nye .custom-textfield .MuiIconButton-root:focus {
    background-color: white !important;
  }

  ._2nye .custom-textfield,.custom-username-textfield{
    background-color: #e0e0e0 !important;
    border-radius: 8px;
  }
  ._2nye .registerr button{
    border: none !important;
    font-weight: bolder;
    text-transform: none;
    color: #000 !important;
  }
  ._2nye .continue-with{
    padding-bottom: 10px;
  }
  ._2nye .dont-have{
    padding-bottom: 10px;
  }
    ._2nye .stay-signed-in-container {
        display: flex;
        align-items: center; 
        justify-content: space-between;
        padding-bottom: 10px;
      }
      ._2nye .forgot-username button{
        border: none !important;
        color: #000!important;
        text-transform: none;
      }
  ._2nye .image{
    display: flex;
    flex-direction: column !important; 
    justify-content: center; 
    /* align-items:center ; */
    background-color: #e6e6e6 !important;
    padding: 40px;
    padding-top: 60px !important;
  }
  ._2nye .image img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 70% !important;
    width: 90% !important;
    padding-left: 40px;
  }
  ._2nye .need-help-main{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px !important;
  }
 ._2nye .need-help{
    background-color: #ffff !important;
    width: 60% !important;
    margin-top: 25px;
    padding: 10px;
    border-radius: 15px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    
 }
 ._2nye .slick-dots li button:before {
    color: white !important;
  }
  .slick-arrow,
._2nye .slick-prev {
  display: none !important;
}
._2nye .slick-dots {
    position: absolute;
    bottom: 20px !important;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
 ._2nye .needhelp-left{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;

 }
 ._2nye .needhelp-left h3{
    margin: 0px;
    padding-bottom: 5px;
 }
 ._2nye .needhelp-left p{
    color: #7C7F82;
    font-size: 10px;
    font-weight: 700;
 }
 ._2nye .contactttus{
    display: flex;
    justify-content: center;
    align-items: center
 }
 ._2nye .contactttus button{
    background-color: #F9C623;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    border: none;
 }

  
  ._2nye .paper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
    background-color: #F2F2F2 !important;
    box-shadow: none !important;
  }
  
  ._2nye .form {
    width: 100%;
    margin-top: 8px;
  }
  
  .custom-textfield .MuiCheckbox-root,
  ._2nye .custom-username-textfield .MuiCheckbox-root {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #f0ad4e;
  }
  
  .custom-textfield .MuiCheckbox-input:checked ~ .custom-textfield .MuiCheckbox-label::before,
  ._2nye .custom-username-textfield .MuiCheckbox-input:checked ~ .custom-username-textfield .MuiCheckbox-label::before {
    color: #000;
  }

  ._2nye .stepper-logo{
  
    padding-left: 30PX;
    height: 70px ;
    padding-top: 10px !important;
 
  }
  /* ._2nye .stepper-logo img{
    height: 80% !important;
  } */
  ._2nye .stepper-header{
    background-color: #15101F !important;
    display: flex;
    justify-content: space-between;
    align-items:  center;
  }


@media (max-width: 767px) {
    ._2nye .root {
      flex-direction: column;
      align-items: center;
    }
  
    ._2nye .image {
      padding: 20px;
      padding-top: 60px !important;
    }
  
    ._2nye .image img {
      padding-left: 0;
      height: 50% !important;
      width: 100% !important
    }
  
    ._2nye .need-help {
      width: 100% !important;
    }
  
    ._2nye .needhelp-left {
      padding: 10px;
    }
  
    ._2nye .stepper-logo {
      padding-left: 10px;
      height: 50px;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
  
    ._2nye .stepper-logo img {
      height: 60% !important;
    }
  }
  

  @media (min-width: 768px) and (max-width: 1023px) {
    ._2nye .root {
      flex-direction: row;
      align-items: stretch;
    }
  
    ._2nye .image {
      width: 40%;
      padding: 20px;
      padding-top: 60px !important;
    }
  
    ._2nye .image img {
      padding-left: 40px;
      height: 70% !important;
      width: 80% !important;
    }
  
    ._2nye .need-help {
      width: 70% !important;
      /* display: flex !important;
      flex-direction: column !important;
      align-items: center;
      justify-content: center; */
    }
    ._2nye .needhelp-left{
        padding-left: 5px !important;
        padding-right: 0px !important;
    }
    ._2nye .paper {
      width: 60%;
    }
  }
  
 
  @media (min-width: 1024px) {
    ._2nye .root {
      flex-direction: row;
      align-items: stretch;
    }
  
    ._2nye .image {
      width: 50%;
      padding: 40px;
      padding-top: 60px !important;
    }
  
    ._2nye .image img {
      padding-left: 40px;
      height: 70% !important;
      width: 90% !important;
    }
  
    ._2nye .paper {
      width: 50%;
    }
  }