.container{
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding:0 ;
}
.title{
    text-align: center ;
    color: #1b1c42;
}
/* SWITCH STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.plandiv{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px;
    border: 1px solid black;
    gap: 4px;
    width: 200px;
    height: 32px;
    border-radius: 5px;
}
.butdiv_1{
width: 100px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 5px;
font-weight: 600;
cursor: pointer;
}
.butdiv_2{
width: 100px;
display: flex;
justify-content: center;
align-items: center;
background: #1b1c42;
color: #ffffff;
border-radius: 5px;
font-weight: 600;
cursor: pointer;
}

.pricing-box{
    width: 100%;
    display: table;
}
.pricing_list{
    display: table-cell;
    border: 1px solid rgb(175, 175, 255);
    border-right:1px solid rgb(175, 175, 255) ;
    width: 25%;
    vertical-align: top;
    background-color: #ffffff;
    border-left:1px solid transparent;
    border-radius: 2px;
}
.pricing_list:first-of-type {
    border-left: 1px solid rgb(175, 175, 255);
}

.pricing_block{
    padding: 20px 8% 40px;
    border-bottom: 1px solid rgb(175, 175, 255);
}
.pricing_block h4{
    color: #64687c;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 4px;
    text-transform: uppercase;
}
.plan-text-underline {
    width: 60px;
    border-bottom: 5px solid #3c60ee;
    margin-bottom: 30px;
    margin-top: -10px;
}
.amount_div p{
    line-height: normal;
    margin-bottom: 10px;
}
.price_symbol{
    font-size: 19px;
    position: relative;
}
.price_value{
    font-size: 49px;
    font-weight: 700;
    color: #20284d;
}
.discription{
    font-size: 10px;
    color: #64687c;
    margin-top: 25px;
    font-weight: 700;
    /* padding: 10px; */
    height: 45px;
    letter-spacing: 0.5px;
}
.price_features{
    padding: 20px 8% 0 ;
    list-style: none;
}
.price_features li{
    margin: 20px;
    position: relative;
    line-height: 22px;
    font-size: 10px;
    font-weight: 700;
    color: #64687c;
  }
.price_features li::before{
    content: url(../../public/assets/images/tick.svg);
    width: 14px;
    position: absolute;
    left: -20px;
    top: 2px;
}
.button{
    padding: 10px 30px;
    border: 1px solid #246cfe;
    background: #e9f1fe;
    color: #246cfe;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 25px;
    /* margin-left: 15px; */
    font-family: "objectivity";
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.button:hover{
    background: #246cfe;
    color: #e9f1fe;
}

