._5pwz {
    font-family: 'Roboto', sans-serif;
}

._5pwz header {
    height: 84px;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px;
}

._5pwz header h3 {
    all: unset;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 25px;
}

._5pwz header span {
    font-size: 13px;
    color: rgb(24, 143, 255);
}

._5pwz main {
    display: flex;
    min-height: calc(100vh - 84px);
    background-color: rgb(249, 249, 250);
}

._5pwz main .sauce-paid {
    flex: 1;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    padding-bottom: 50px;
}

._5pwz main .sauce-paid .container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: -80px;
    width: 774px;
    background-color: white;
    margin-left: 45px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

._5pwz main .sauce-paid .container h2 {
    all: unset;
    color: rgb(49, 49, 49);
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
    font-family: 'Roboto', sans-serif;
}

._5pwz main .sauce-paid .container p {
    all: unset;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    line-height: 25px;
    color: rgb(108, 108, 108);
}

._5pwz main .their-apex {
    flex: 1;
}

._5pwz main .their-apex .container {
    margin-top: 20px;
    padding-bottom: 70px;
    width: 360px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

._5pwz main .their-apex .container .Logo {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: 600;
}

._5pwz main .their-apex .runnier-cap {
    height: 90px;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

._5pwz main .their-apex .runnier-cap .big {
    font-size: 21px;
    font-weight: 500;
}

._5pwz main .their-apex .agonised-men {
    margin-top: 40px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

._5pwz main .their-apex .agonised-men .container {
    all: unset;
    width: 80%;
    position: relative;
    display: flex;
    justify-content: center;
}

._5pwz main .their-apex .agonised-men .container span {
    position: absolute;
    left: 0px;
    bottom: 10px;
    font-size: 15px;
    color: rgb(157, 157, 157);
    pointer-events: none;
    transition: bottom 0.3s ease, font-size 0.3s ease;
}

._5pwz main .their-apex .agonised-men .container span.focused {
    font-size: 12.5px;
    bottom: 32px;
    color: rgb(38, 38, 38);
}

._5pwz main .their-apex .agonised-men input {
    width: 100%;
    height: 30px;
    font-size: 15px;
    border: none;
    border-bottom: 1px solid rgb(185, 189, 197);
    outline: none;
}

._5pwz main .their-apex .agonised-men input.focused {
    border-color: rgb(24, 143, 255);
}

._5pwz ul {
display: flex;
list-style: none;
gap: 40px;

}

._5pwz main .slick-dots{
    margin-bottom: 50px;
}
._5pwz ul a{
    text-decoration: none;
}


._5pwz main .their-apex .agonised-men button {
    width: 80%;
    height: 43px;
    margin-top: 24px;
    border: none;
    font-size: 17px;
    font-weight: 600;
    color: white;
    background-color: rgb(24, 143, 255);
    border-radius: 25px;
    cursor: pointer;
}

._5pwz main .their-apex .agonised-men .bioclean-teed {
    color: rgb(240, 22, 47);
    width: 80%;
    margin-top: 10px;
    font-size: 13px;
}

._5pwz main .their-apex .enactors-map {
    color: rgb(24, 143, 255);
    font-size: 15px;
    width: 80%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

._5pwz main .their-apex .enactors-map>div {
    display: flex;
    align-items: center;
    gap: 5px;
}

._5pwz main .their-apex .enactors-map>div input {
    width: 15px;
    height: 15px;
}

._5pwz main .their-apex .enactors-map span {
    cursor: pointer;
}
.carousal{
    background: grey;
}