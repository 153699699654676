.Pass_div{
    display: flex;
    height: calc(100vh - 84px);
    justify-content: center;
    align-items: center;
    gap: 3rem;
}
.Box_div{
    width: 400px;
    padding: 10px;
    margin-top: 50px;
}
.Box_div h1{
    font-family: 'Objectivity';
    font-size: 35px;
    font-weight: 700;
    color: #21A2DB
}
.Box_div p{
    font-size: 10px;
    font-weight: 500;
    padding-bottom: 10px;
    color: #48b9ea
}
.Img_div{
    padding-top: 50px;
}
.field_div{
    display: flex;
    flex-direction: column;
}
.field_div span{
    color: red;
    font-size: 10px;
    font-weight: 500;
}
.field_div input{
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid gray;
    outline: none;
    
}
.field_div input:focus{
    border: 1px solid #21A2DB;
  }

.field_div .btn{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: none;
    background: #21A2DB;
    color: white;
    cursor: pointer;
}
.link{
    display: flex;
    justify-content: center;
}
.link a{
    text-decoration: none;
    color: #21A2DB;
    font-size: 10px;
    font-weight: 700;
    padding: 20px;
}
.btn .css-18lrjg1-MuiCircularProgress-root{
    color: white;
}
