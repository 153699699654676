.MuiCard-root .pricing-image{
    width: 70%;
    margin-top: 25px;
}
.makeStyles-section-1 .card-1{
    border-top: 5px solid blue !important;
    /* border-left: 5px solid blue !important; */
    border-radius: 15px;
}
.makeStyles-section-1 .card-2{
    border-top: 5px solid yellow !important;
    /* border-left: 5px solid yellow !important; */
    border-radius: 15px;
}
.makeStyles-section-1 .card-3{
    border-top: 5px solid orange !important;
    /* border-left: 5px solid orange !important; */
    border-radius: 15px;
}
.makeStyles-section-1 .card-4{
    border-top: 5px solid red!important;
    /* border-left: 5px solid red !important; */
    border-radius: 15px;
}

.makeStyles-section-1 .MuiTypography-h4{
    margin-top: 20px;
}


