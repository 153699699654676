._0yet {
    position: relative;
}

._0yet .cabresto-baa {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(128, 128, 128, 0.552);
    z-index: 3;
}

._0yet .view-box .css-ahj2mt-MuiTypography-root {
    color: #9b9b9b !important;
}

._0yet .cabresto-baa .corsair-bomb {
    display: flex;
    justify-content: center;
}

._0yet .cabresto-baa .corsair-bomb .incenter-kava {
    background-color: white;
}

._0yet .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

._0yet ._2sma {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-weight: 500;
}

._0yet ._2sma .header {
    font-size: 20px;
    margin-top: 10px;
}

._0yet ._2sma .corsair-bomb {
    display: flex;
    gap: 40px;
    margin-bottom: 40px;
    margin-top: 20px;
}

._0yet ._2sma .corsair-bomb .peels-dive {
    width: 50vw;
    margin: auto;
    padding: 30px 40px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    margin-top: 20px;
}

._0yet ._2sma .corsair-bomb .peels-dive .eschar-link {
    margin-top: 20px;
    margin-bottom: 30px;
}

._0yet ._2sma .corsair-bomb .peels-dive > button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgb(25, 118, 210);
    width: fit-content;
    padding: 12px 15px;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
}

._0yet ._2sma .corsair-bomb .incenter-kava {
    padding: 30px 40px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
}

._0yet ._2sma .incenter-kava {
    display: flex;
    flex-direction: column;
}

._0yet ._2sma .incenter-kava input {
    width: 350px;
    outline: none;
    height: 40px;
    border-radius: 20px;
    border: 1px solid grey;
    font-size: 16px;
    padding-left: 15px;
    margin-top: 10px;
}

._0yet ._2sma .incenter-kava p {
    font-size: 14px;
    color: rgb(62, 62, 62);
}

._0yet ._2sma .incenter-kava button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgb(25, 118, 210);
    width: fit-content;
    padding: 12px 15px;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
}

._0yet .btn{
    all: unset;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgb(25, 118, 210);
    width: fit-content;
    padding: 12px 15px;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
    margin-bottom: 30px;
}

._0yet ._2sma .cloth-rue {
    margin-top: 30px;
}

._0yet ._2sma .cloth-rue p {
    font-size: 14px;
    color: rgb(62, 62, 62);
}

._0yet ._2sma .cloth-rue button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgb(25, 118, 210);
    width: fit-content;
    padding: 12px 15px;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
    margin-bottom: 30px;
}
._0yet .btn{
    all: unset;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgb(25, 118, 210);
    width: fit-content;
    padding: 12px 15px;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
    margin-bottom: 30px;
}

._0yet ._2sma .cloth-rue .clicking-lob {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: rgb(25, 118, 210);
    padding: 5px 15px;
    font-weight: 700;
    font-size: 30px;
    color: white;
    border-radius: 20px;
    cursor: pointer;
}

._0yet ._2sma .cloth-rue .clicking-lob span {
    transform: translate(0.5px, 2px);
}

._0yet ._2sma .bologna-hip {
    display: flex;
    margin-top: 10px;
}

._0yet ._2sma .bologna-hip li {
    list-style: none;
}

._0yet ._2sma .cloth-rue .clicking-lob {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: rgb(25, 118, 210);
    padding: 5px 15px;
    font-weight: 700;
    font-size: 30px;
    color: white;
    border-radius: 20px;
    cursor: pointer;
}

._0yet ._2sma .cloth-rue .clicking-lob span {
    transform: translate(0.5px, 2px);
}

._0yet ._2sma .bologna-hip {
    display: flex;
    margin-top: 10px;
}

._0yet ._2sma .bologna-hip li {
    list-style: none;
}

._0yet ._2sma .amelcorn-dors {
    border: 1px solid grey;
    border-top: 6px solid grey;
    border-bottom: none;
}

._0yet ._2sma .amelcorn-dors .box {
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid grey;
}

._0yet ._2sma .amelcorn-dors .box span:first-of-type {
    width: 300px;
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid grey;
    color: rgb(74, 74, 74);
}

._0yet ._2sma .amelcorn-dors .box span {
    padding-left: 20px;
    font-family: 'Roboto', sans-serif;
}

._0yet ._2sma .amelcorn-dors .box span:nth-of-type(2) {
    font-weight: 400;
}
.tables_div{
    width: 100%;
    margin-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
}
.some_title{
    margin: 20px;
    font-size: 15px;
    font-weight: 600;
}

._0yet ._2sma .third-dox {
    margin-bottom: 20px;
}

._0yet ._2sma .third-dox h2 {
    color: rgb(64, 64, 64);
}

._0yet ._2sma .rifer-hoe {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: auto;
    margin-top: 20px;
}

._0yet ._2sma .rifer-hoe span {
    font-family: 'Roboto', sans-serif;
    margin: 0px;
    padding: 0px;
    font-weight: 400;
    font-size: 14px;
    color: rgb(25, 118, 210);
    cursor: pointer;
}

._0yet ._2sma .rifer-hoe button {
    transform: translateY(5px);
}

._0yet .chyme-jets {
    width: 100%;
    margin-top: 30px;
    font-family: 'Roboto', sans-serif;
}

._0yet .chyme-jets > span {
    font-weight: 700;
}

._0yet .chyme-jets > div {
    margin-top: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
}

._0yet .chyme-jets input,
._0yet .chyme-jets label {
    cursor: pointer;
}

._0yet .edit {
    padding: 2px 8px;
    border-radius: 5px;
    color: white;
    background-color: green;
}

._0yet .view {
    padding: 2px 8px;
    border-radius: 5px;
    color: white;
    background-color: grey;
}

._0yet .none {
    padding: 2px 8px;
    border-radius: 5px;
    color: white;
    background-color: rgb(219, 93, 93);
}

._0yet .css-1hv8oq8-MuiStepLabel-label {
    white-space: nowrap;
}

._0yet .css-wn4ipv {
    width: 80vw;
}

._0yet ._2sma .robust-did {
    width: 60vw;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 40px 50px;
    border-radius: 10px;
}
