.app-component-topheader{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url('../../../public/assets/images/header-bg.svg');
    padding: 15px 30px;
    &__brand{
        display: flex;
        width: 212px;
        border-right: 1px solid #fff;
        img{

        }
    }
    &__action{
        display: flex;
        width: calc( 100% - 212px );
        .MuiButtonBase-root{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #FFFFFF;
            text-transform: capitalize;
            img{
                width: 20px;
                margin-right: 10px;
            }
        }
    }
}
.logout-login{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 30px;

}
.logout-style{
    font-family: "Objectivity";
    font-weight: 500;
    font-size: 14px;
    color: #626262;
}
.app-new-component-header{
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
}
.app-form-field p{
    font-family: 'Objectivity';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

}
.Booking-details-content{
    border-bottom: 1px solid #000;
    margin-bottom: 5px;
}
.app-new-component-header h5{
    margin: 0px 0px 20px 0px;
}