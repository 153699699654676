._9wkb .container .pythonic-ran {
    display: flex;
    justify-content: center;
    font-size: 15px;
}

._9wkb .agonised-men .firedamp-tag {
    margin-top: 30px;
    font-size: 15px;
    color: rgb(24, 143, 255);
    cursor: pointer;
}

._9wkb .agonised-men .recipes-pis {
    color: rgb(163, 70, 81);
    width: 80%;
    margin-top: 10px;
    font-size: 13px;
}
._5pwz main .their-apex .agonised-men .Iconeye{
    width: 30px;
    height: 20px;
    margin-top: 7px;
    margin-left: -28px;
    background-color: white;
    color: rgb(111, 110, 110);
}
._5pwz main .their-apex .agonised-men .Textfont{
    font-size: 15px;
}
._5pwz main .their-apex .agonised-men .Passfont{
    font-size: 25px;
}