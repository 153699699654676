.MuiContainer-root {
    font-family: "objectivity";
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
}

.css-nzinn5-MuiContainer-root .css-gepadz-MuiTypography-root {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    font-family: 'Objectivity';
    font-weight: 700;
    color: #21A2DB;
}

.css-nzinn5-MuiContainer-root .css-5lbw0b-MuiTypography-root {
    display: flex;
    font-family: 'Objectivity';
    justify-content: center;
    font-size: 35px;
    font-weight: 700;
    margin-top: 20px;
    color: #21A2DB;
}

.css-nzinn5-MuiContainer-root .css-ag7rrr-MuiTypography-root {
    font-family: 'Objectivity';
    font-weight: 700;
    color: #21A2DB;
}


.css-nzinn5-MuiContainer-root .css-101ca9i-MuiTypography-root-MuiLink-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #21A2DB;
    text-decoration: none;
    padding-top: 10px;
    font-size: 15px;
    font-weight: 700;
}

.MuiContainer-root .css-16g5rpk-MuiGrid-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.MuiContainer-root .css-binzgt {
    margin-top: 20px;
}

.MuiContainer-root .css-ahj2mt-MuiTypography-root {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.MuiContainer-root .css-1vhaqj4-MuiButtonBase-root-MuiButton-root {
    background-color: #56c2f0;
    height: 40px;
}

.MuiContainer-root .css-1vhaqj4-MuiButtonBase-root-MuiButton-root:hover {
    background-color: #21A2DB;
    ;
}

.MuiContainer-root .css-2ulfj5-MuiTypography-root {
    color: rgb(118, 113, 113);
}

.MuiContainer-root .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    height: 0.75rem;

}
.MuiContainer-root .css-vj1n65-MuiGrid-root{
    display: flex;
    justify-content: center;
}
.MuiContainer-root .css-18lrjg1-MuiCircularProgress-root{
    color: white;
}

.MuiGrid-root a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #21A2DB;
    margin-top: 10px;
    font-size: 15px;
    font-weight: 600;
}