.registerd_organisations{
    margin-bottom: 40px;
}
.registerd_organisations .customers h2{
    font-size: 24px;
    line-height: 35px;
    font-weight: 600;
    margin: 0px 0px 10px 0px;
}
.registerd_organisations_box{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 40px 20px;
    // border-radius: 4px;
}
.registerd_organisations_box h4{
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    margin: 0;
    color: #687693;
}

.registerd_organisations_box p{
    font-size: 15px;
    font-weight: 600;
    margin: 0;
}
.registerd_organisations_box img{
    width: 20px;
    margin-bottom: 5px;
}
.registered_table .MuiTableCell-body{
    font-family: 'Objectivity' !important;
    font-style: normal !important;
    font-weight: 500 !important;
}
.registered_table .MuiTableCell-head{
    font-family: 'Objectivity' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    background-color: #DDD;
    font-weight: 600 !important;
}
.register_organisation_heading{
    display: flex;
    align-items: center;
    font-family: 'Objectivity' !important;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 600 !important;
    color: #3b3b3b;
    border-left: 5px solid #F9C623;
    padding-left: 15px;
}
.MuiTablePagination-selectLabel{
    font-family: 'Objectivity' !important;
    font-size: 14px !important;
    margin-bottom: 10px !important;
    font-weight: 500 !important;
}
.MuiTablePagination-select, .MuiTablePagination-displayedRows{
    font-family: 'Objectivity' !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}
// .registered_table .MuiTableCell-body p span{
//     font-size: 10px !important;
//     background: #ff0000 !important;
//     color: #FFF !important;
//     padding: 2px 5px !important;
//     border-radius: unset !important;
// }