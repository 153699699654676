.app-component-booking-list{
    .MuiButtonBase-root{
        background: #FFFFFF;
        box-shadow: 0px 5px 26px rgba(0, 0, 0, 0.06);
        border-radius: 20px;
        width: 220px;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
        min-height: 45px;
        border: 1px solid #F9C623;
        p{
            margin: 0;
            font-family: 'Objectivity';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #9A9A9A;
            text-transform: capitalize;
            span{
                font-weight: 700;
                color:#008000;
            }
        }
        img{
            width: 9px;
        }
    }
}
.custom-menu{
    .MuiMenu-paper{
        width: 300px;
        border-radius: 20px;
    }
}
.app-component-booking-list-card{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    background: #F8FAFC;
    padding:10px 15px;
    border-radius: 10px;
    h4{
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #252525;
        margin:0;
    }
    p{
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #252525;
        margin:2px 0 0 0;
    }
    ul{
        display: flex;
        align-items: center;
        padding:0;
        margin:0;
        list-style: none;
        li{
            display: flex;
            align-items: center;
            margin-left: 10px;
            img{
                width: 16px;
                height: 16px;
                margin-right: 6px;
            }
            span{
                font-family: 'Objectivity';
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 24px;
                color: #252525;
            }
        }
    }
}