._5pxd .master button{
    background-color: #D8232A !important;
    border: none;
    padding: 10px 15px;
    color: white;
    border-radius: 10px;
    font-size: 14px;
  
  }
._5pxd .master{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    background-size: cover;
    text-align: center;
    background-image: url('/public/Images/landing-bg.png');
    width: 100%;
    height: 100%;
  }
  ._5pxd .master img{
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  ._5pxd .mast{
    margin: 40px 0px;
  }
  .app-new-unlock-bg{
    padding: 80px 0px;
  }
  .unlock-btn{
    margin-top: 20px;
  }
  ._5pxd .mast h1{
    color: #FFF;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    margin: 0px 0px 20px 0px;
    color: #ffff;
  }
  .app-banner-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  ._5pxd .mast h1 span{
    color: #F9C623;
    
  }
  ._5pxd .mast p{
    color: #A09CA9;
    font-weight:700;
    margin: 0px;
  }
  ._5pxd .image-container{
  position: relative;
}

._5pxd .image-container::before{
    content: '';
    position: absolute;
    background-image: url('/public/Images/OBJECTS.png');
    width: 110px;
    height: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 52px;
    left: -106px;
}
._5pxd .image-container p {
     width: 500px;
    font-size: 15px;  
    font-weight:500;
    line-height: 25px;
    margin: 0px;
}
.app-banner-btn{
  margin-top: 30px;
}
._5pxd .marquee-content{
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
  }
  ._5pxd .marquee-item {
    width: 25%;
    height: auto;
    object-fit: contain;
    display: inline-block;
    margin: 0;
    text-align: center;
    opacity: .8;
    float: left;
    transition: all .2s ease-out;
    filter: grayscale(1);
  }
  ._5pxd .marquee-item img{
    width: 120px;
    height: 60px;
    object-fit: contain;
  }
  ._5pxd .marquee-item:hover {
    transform: scale(1.2);
    opacity: 1;
    filter: unset;
  }
  ._5pxd .marquee {
    height: 90px;
    margin-top: 0px;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
  }
  ._5pxd .marquee-inner {
    display: flex;
    width: 200%;
    margin: 10px 0;
    position: absolute;
    animation: marquee 40s linear infinite;
    justify-content: space-between;
  }
  ._5pxd .marquee-inner:hover {
    animation-play-state: paused;
  }
  @keyframes marquee {
    0% { left: 0; }
    100% { left: -100%; }
  }
  ._5pxd .contain {
    margin-top: 150px;
  }
  ._5pxd .cont{
    padding: 80px;

  }
  ._5pxd .cont p{
    color: #7C7F82;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin: 0px 0px 0px 0px;
  }
  ._5pxd .start button{
    background-color: #d34835 !important;
    border: none;
    padding: 10px 15px;
    color: white;
    border-radius: 10px;
    font-size: 14px;
  }
  ._5pxd .position3{
    position: relative;
  
  }
  ._5pxd .position3 img{
    width: 100%;
  }
  ._5pxd .position3::before{
    content: '';
    position: absolute;
    background-image: url('/public/Images/pos3.png');
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    top: 96px;
    left: 12px;
  }
  ._5pxd .position4 img{
    width: 100%;
  }
  ._5pxd .bg{
    background-color: #F4F5F6;
  
  }
  ._5pxd .pricing{
    text-align: center;
    padding-top: 30px;
  }
  ._5pxd .borderRadiusTabsWrapper {
    border-radius: 15px !important;
  }
  ._5pxd .border .MuiTabs-scroller .MuiTabs-flexContainer{
    position: relative;
  }
  ._5pxd .border .MuiTabs-scroller .MuiTabs-flexContainer::after{
      content: '';
      position: absolute;
      background-image: url('/public/Images/OBJECTS2.png');
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      top: 5px;
      left: 870px;
      display: inline-block;
  }
  ._5pxd .card-size{
    border-radius: 20px !important;
  }
  ._5pxd .card-size:hover{
    background-color: #15101F;
    color: white;
  
  }
  ._5pxd .margin-top-color {
    height: 16px;
    width: 100%;
  }
  ._5pxd .titlee{
    padding-top: 30px !important;
    font-weight: bold !important;
  }  
  ._5pxd .days-container {
    display: inline-flex;
    align-items: baseline; 
  }
  
  ._5pxd .days-container p {
    margin-right: 5px; 
    font-size: 35px;
    font-weight: bold;
  }
  ._5pxd .content{
    color: #7C7F82;
  }
  ._5pxd .includes{
    font-weight: bold !important;
    font-size: 20px!important;
  }
  ._5pxd .month{
    color:  #7C7F82;
  }
  ._5pxd .tick-marks {
    position: relative;
  }
  
  ._5pxd .tick-mark {
    position: relative;
    display: inline-block;
  
    
  margin-right: 15px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  ._5pxd .card-button button{

    width: 100%;
    }
    ._5pxd .half-above-line {
 
        font-size:23px;
        position: relative;
        top: -0.2em;
        padding-right: 10px;
        color: #7C7F82;
      }
      ._5pxd .position{
        position: relative;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
      }
      ._5pxd .position::before{
        content: '';
        position: absolute;
        display: inline-block;
        filter: brightness(100);
        background-image: url('/public/Images/pos1.png');
        width: 100px;
        height: 100px;
        background-repeat: no-repeat;
        top: 6px;
        left: -137px;
      }
      ._5pxd .position img{
        position: relative;
        top: 10px;
        right: 30px;
      
      }
      ._5pxd .position button{
        position: relative;
        right: 20px;
      }
      ._5pxd .logo-slider-content{
        background-color: #FAFAFA;
      }
      ._5pxd .logo-slider-content p{
        text-align: center;
        padding: 30px 0px;
        font-weight: bolder;
        color: #24292E;
      }
      .privacy-policy{
        background-color: #3a052b;
        width: 100%;
       padding: 100px 0px;
      }
      .privacy-policy-content{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .privacy-policy-content h1{
        color: #fff;
        font-size: 50px;
        line-height: 60px;
        margin: 0;
        text-align: center;
      }
      .intro{
        margin: 80px 0px;
      }
      .privacy-policy .MuiContainer-root,
      .intro .MuiContainer-root{
        box-shadow: unset !important;
      }
      .intro-title p,
      .privacy-policy-main-content p{
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        margin: 0;
      }
      .privacy-policy-main-content p a{
        font-size: 16px;
        line-height: 26px;
        font-weight: 700 !important;
        margin: 0;
        text-decoration: none;
        color:#15101F;
      }
      .privacy-policy-main-content{
        margin-top: 30px;
      }
      .privacy-policy-main-content h4{
        font-size: 20px;
        line-height: 30px;
        color: #000 !important;
        margin: 0px 0px 10px 0px;
      }
      .privacy-policy-mini-content ul li{
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .privacy-policy-contact-content{
        margin-top: 30px;
      }
      .privacy-policy-contact-content h5{
        font-size: 18px;
        line-height: 30px;
        color: #000 !important;
        margin: 0px 0px 10px 0px;
      }
      .privacy-policy-contact-content p{
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        margin: 0;
      }
      .privacy-policy-contact-content ul li{
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .privacy-policy-contact-content .contact-us    ul li a{
          font-size: 16px !important;
          line-height: 26px !important;
          font-weight: 700 !important;
          margin: 0 !important;
          text-decoration: none !important;
          color:#15101F;
      }