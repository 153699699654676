._3qty .css-y8ay40-MuiTableCell-root {
    background-color: antiquewhite;
}

._3qty .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none;
    background-color: transparent;
}

._3qty .glosseme-rant {
    display: flex;
}

._3qty .bedabble-ions {
    width: fit-content;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    color: white;
    background-color: rgb(235, 202, 96);
    border-radius: 20px;
}

._0sgv_table {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
._0sgv_table div{
    height: 30px;
    display: flex;
    align-items: center;
    padding: 30px 10px;
}


