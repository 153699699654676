.hall-cell{
    max-width: 220px !important;
}
.hall-cell2{
    max-width: 120px;
}
.table-check-box .MuiCheckbox-root{
padding: 0px !important;
}
.app-new-component-table{
    background-color:#F8FAFC;
    border-radius: 20px;
    margin-top: 30px;
}
.app-new-main-form-field{
    padding: 20px;
}
.app-new-company-forms{
    margin-top: 60px;
}
.app-new-component-table2{
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(5px);
    border-radius: 20px;
}
.app-btn2{
    &.MuiButtonBase-root{
        font-family: 'Objectivity';
        padding: 6px 22px;
        border-radius: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        text-transform: capitalize;
    }
    &-primary{
        &.MuiButtonBase-root{
            background:none;
            color:#382F00 ;
            border: 1px solid #6c5c05;
            &:hover{
                background: #6c5c05;
                color: #F8FAFC;
            }
        }
    }
}