.css-hip9hq-MuiPaper-root-MuiAppBar-root {
    background: white !important;
    color: #62C3EC !important;
}
.MuiContainer-root .css-1q39md6-MuiButtonBase-root-MuiButton-root{
    color:#62C3EC;
}
.css-r6ewbb-MuiToolbar-root {
    padding : 0px 75px 0px 75px ;
}

.box_div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 100px;
    align-items: center;
    margin-bottom: 60px;
}
.box_div h1{
    text-align: center;
    color:#62C3EC;
}
.box_div p{
    text-align: center;
    width: 410px;
    font-weight: 500;
}
.btn_btn{
    padding: 10px 25px 10px 25px;
    border: none;
    background: #62C3EC;
    border-radius: 10px;
    color: white;
}
.Img_Div{
    position: relative;
    display: flex;
    justify-content: center;
}
.main_img{
        position: absolute;
}