
.PaxAndHallSelect-css{
    display: flex;
    justify-content: center;
}.app-component-pax-hall-select{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: end;
    margin: 20px 0;
    &__wrapper{
        background: #FFFFFF;
        box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.06);
        border-radius: 77px;
        display: flex;
        align-items: center;
        padding:7px 2px 7px 16px;
        border: 1px solid #F9C623;
    }
    &__pax{
        display: flex;
        align-items: center;
        img{
            width: 16px;
            margin-right: 5px;
        }
        .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input{
            font-family: 'Objectivity';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            color: #252525;
            width: 50px;
        }
    }
    &__halls{
        border-left: 1px solid #626262;
        padding-left: 10px;
        .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input{
            padding:0;
            font-family: 'Objectivity';
            font-weight: 500;
            font-size: 14px;
            color: #626262;
            width: 200px;
        }
        .MuiSvgIcon-root{
            background-color: #ffffff;
        }
    }
    &__date{
        
        display: flex;
        .MuiFormControl-root{
            width: 200px;
            border: 1px solid #F9C623;
            border-radius: 77px;
        }
        .MuiFormLabel-root.MuiInputLabel-root{
            font-family: 'Objectivity';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #626262;
                top: -6px;
        }
        .MuiSvgIcon-root{
            color: $primary-color;
            font-size: 18px;
        }
        .MuiInputBase-root.MuiOutlinedInput-root{
            background-color: #ffffff;
            border-radius: 30px;
            .MuiOutlinedInput-input{
                padding: 8px 14px;
                font-family: 'Objectivity';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #626262;
            }
        }
    }
    .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input{
        padding:0;
        em{
            font-style: normal;
        }
    }
    .MuiOutlinedInput-notchedOutline{
        border:0;
    }
    .MuiButtonBase-root{
        margin-left: 16px;
    }
}