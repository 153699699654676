._0jor1{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
}

._0jor .upgrade_container{
    width: 65%;
    height: 90vh;
    background-color: #F8FAFC;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 3px 3px 15px rgb(45, 44, 44);
}

._0jor .upgrade_container .upgrade_grid{
    width: 100%;
    height: 60%;
    margin: 40px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

._0jor .upgrade_container .Upgrade_card{
    width: 28%;
    height: 100%;
    margin: 10px;
    border-radius: 25px;
}

.selected_period{
    color: white;
    background-color:#B4B4B8;
    border-radius: 5px;
}