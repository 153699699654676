._1ywv .app-card__content {
    margin: 8px;
    padding: 0px;
}

._1ywv .seamers-arms .disabled * {
    color: rgb(175, 175, 175) !important;
    border-color: rgb(175, 175, 175) !important;
}

.MuiFormControl-root .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 4px 0 5px !important;
}

.css-apqrd9-MuiTableBody-root .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    width: 50px;
}

.cardDiv {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
    margin-bottom: 10px;
}

.MuiTableContainer-root .css-y8ay40-MuiTableCell-root {
    text-wrap: nowrap !important;
}

.MuiTablePagination-toolbar {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
}

.MuiTablePagination-root {
    justify-content: end;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Objectivity';
    font-weight: 700;
    font-size: 1rem;
}