.app-form-field{
    width: 100%;
    margin: 5px 0;
    .MuiFormControl-root{
        width: 100%;
    }
    .MuiInputBase-root{
        width: 100%;
    }
    .MuiOutlinedInput-root{
        border-radius: 20px;
    }
    .MuiOutlinedInput-input{
        padding: 10px 12px;
        font-size: 14px;
    }
    .MuiInputLabel-root{
        font-weight: 500;
        font-size: 14px;
        color: #626262;
        top: -5px;
    }
    .MuiOutlinedInput-notchedOutline{
        border: 1px solid #6A6A6A;
    }
    .Mui-focused{
        &.MuiInputLabel-root{
            top: 0;
            color: #382F00;
        }
        .MuiOutlinedInput-notchedOutline{
            border-color:#382F00 !important;
            border-width: 1px !important;
        }   
    }
}
.app-check-btns-group{
    display: flex;
    flex-direction: column;
    &__title{
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #382F00;
        margin: 0;
    }
    &__list{
        padding:0;
        margin: 0;
        list-style: none;
        display: flex;
        &__item{
            &:not(:last-child){
                margin-right: 10px;
            }
        }
    }
}
.MuiFormControlLabel-root{
    .MuiTypography-root{
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #626262;
    }
    .MuiSvgIcon-root{}
}
.MuiButtonBase-root.MuiCheckbox-root.Mui-checked,
.MuiButtonBase-root.MuiCheckbox-root.Mui-checked + .MuiFormControlLabel-label{
    color: #382F00;
    font-weight: 600;
}