.bg-white{
    padding: 60px 0px 0px 0px;
  }
  .bg-col{
    background-color: #F5F5F5
    ;
  }
  .footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }
  
  .social-media-icons {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }
  
  .social-media-icons > * {
    margin-left: 10px;
  }
  .footer-text {
    margin-left: 120px !important; 
    color: #7C7F82;
  }
 
 
  .dec ul{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .dec {
    min-height: 250px !important;
    padding: 0px !important;
    margin: 0px !important;
  }
  .dec ul li h5{
    margin: 0px 0px 0px 0px;
  }
  .footer{
    display: flex !important;
   /* justify-content: center !important; */
    align-items: center !important; 
    min-height: 250px !important;
  }
  .dec ul li{
    list-style: none;
  color: #24292E;
  margin-bottom: 10px;
  font-size: 20px;
    
  }
  .dec ul li a{
    text-decoration: none;
    color:  #656666;
    font-size: 13px;
  
  }
  .bg-col {
    background-color: #F5F5F5;
}
._5zsw{
    display: flex;
    align-items: center;
    justify-content: center;
}
._5zsw .image{
 width: 250px;
 height: 100px;
}
._8vxq{
  display: flex;
}
