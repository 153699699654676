._7xqw .app-page-dashboard__header__list {
    margin-bottom: 20px;
    margin-left: 0px;
    justify-content: flex-start;
}

._7xqw .harping-suck {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

._7xqw .excreta-shun {
    display: flex;
    gap: 6px;
    font-family: "Roboto";
    font-size: 14px;
    color: grey;
    margin-right: 10px;
    background-color: white;
    padding: 10px;
}
