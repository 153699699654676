.app-page-dashboard{
    display: flex;
    flex-direction: column;
   
    &__header{
        margin-bottom: 20px;
    
        
        &__list{
            display: flex;
            align-items: center;
            justify-content: space-between;
            justify-content: end;
            padding:0;
       margin: 0;
            list-style: none;
            
        }
    }
    &__content{
        height: 100%;
    }
}
.app-page-dashboard__header .PaxAndHallSelect-css{
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
}

.banquet-bookings-vertical-scroll{
    max-height: calc(100vh - 165px);
}
.banquet-bookings-table{
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
    
    &__sidebar{
        width: 190px;
        padding:20px 15px;
        box-shadow: 4px 0px 10px #eaeaea;
        border-radius: 16px 0 0 16px;
        h1{
            font-family: 'Objectivity';
            font-style: normal;
            font-weight: 800;
            font-size: 19px;
            color: #382F00;
            margin:0;
        }
        &__menu{
            padding:0;
            margin:30px 0 0 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            &__item{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 0;
                h6{
                    font-style: normal;
                    display: flex;
                    align-items: center;
                    font-weight: 700;
                    font-size: 13px;
                    color: #626262;
                    margin:0;
                    min-height: 28px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .pax-count{
                    display: flex;
                    align-items: center;
                    img{
                        margin-right: 5px;
                    }
                    span{
                        font-weight: 500;
                        font-size: 9px;
                        color: #252525;
                    }
                }
            }
        }
    }
    &__table{
        width: calc(100% - 190px);
        table{
            width: 100%;
            border-collapse: collapse;
            th{
                border-left: 1px solid #b5b5b5;
            }
            td{
                padding: 15.5px 10px;
                border-left: 1px solid #e5e5e5;
            }
            td:nth-child(3n+1){
                border-color: #b5b5b5;
            }
        }
        &__type{
            th{
                font-weight: 500;
                font-size: 9px;
                text-align: center;
                color: #252525;
                border-left: 1px solid #e5e5e5 !important;
                &:nth-child(3n+1){
                    border-color: #b5b5b5 !important;
                }
            }
        }
        &__date{
            font-weight: 700;
            font-size: 13px;
            color: #8B8B8B;
            height: 57px;
        }
    }
    &_button{
        &.MuiButtonBase-root{
            width:50px;
            height: 15.58px;
            border-radius: 20px;
            &.--status-available{
                background: #008000;
                color: #008000;
            }
            &.--status-confirmed{
                background: #FF5A5A;
                color: #FF5A5A;
            }
            &.--status-booked{
                background: #FFBE40;
                color: #FFBE40;
            }
        }
    }
}
.flex-box{
    display: flex;
}
.custom-popper{
    .MuiPaper-root{
        width: 300px;
        border-radius: 10px;
    }
    .slot-popper-card{
        background-image: url('../../../public/assets/images/card-bg.svg');
        padding:15px;
        h1{
            font-family: 'Objectivity';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 129%;
            color: #252525;
            margin: 0;
        }
        p{
            font-family: 'Objectivity';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 129%;
            color: #252525;
            margin: 0;
        }
        span{
            font-family: 'Objectivity';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 129%;
            color: #797979;
        }
        ul{
            padding:0;
            margin:0;
            list-style: none;
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
                li{
                    display: flex;
                align-items: center;
                margin-left: 10px;
                img{
                    width: 16px;
                    height: 16px;
                    margin-right: 6px;
                }
                span{
                    font-family: 'Objectivity';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 24px;
                    color: #252525;
                }
            }
        }
    }
}
.status-badge{
    width: 61px;
    height: 16px;
    background: #008000;
    border-radius: 23px;
}
