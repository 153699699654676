._5gxx .retake-hymn span {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 18px;
    margin-top: 30px;
    color: #a48901;
}

._5gxx .flippy-yelm {
    background-color: white;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
}
