.app-component-table {
    background: #FFFFFF;
    backdrop-filter: blur(5px);
    padding: 12px 18px;
    border-radius: 16px;
    position: relative;
    z-index: 1;

    &__header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        h2 {
            font-family: 'Objectivity';
            font-style: normal;
            line-height: 129%;
            font-weight: 600;
            font-size: 16px;
            color: #c2a300
        }

        &__search {
            position: relative;

            .MuiFormControl-root.MuiTextField-root {
                width: 300px;

                .MuiFormLabel-root {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    color: #626262;
                    top: -6px;

                    &.Mui-focused {
                        top: 2.3px;
                    }
                }

                .MuiInputBase-root {
                    border-radius: 30px;

                    .MuiInputBase-input {
                        padding: 8px 20px;
                    }
                }

                .MuiOutlinedInput-notchedOutline {
                    border-color: #6A6A6A;
                }
            }

            img {
                position: absolute;
                top: 12px;
                right: 15px;
                width: 14px;
            }
        }
    }
}

.app-component-imageslist {
    display: flex;
    align-items: center;

    ul {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0 0 0 10px;
        position: relative;

        li {
            &:not(:first-child) {
                margin-left: -10px;
            }

            img {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
    }
}

.app-table-status {
    display: flex;
    align-items: center;

    img {
        margin-right: 5px;
    }

    span {
        font-weight: 700;
        font-size: 14px;
        color: #515151;
    }
}