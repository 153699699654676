.header{
    padding: 25px !important;
  }
 .navbar-items .start{
  background-color: #d34835 !important;
  border: none;
  padding: 10px 15px;
  color: white;
  border-radius: 10px;
  font-size: 14px;

}
.logo{
    margin-left: 30px;
}
.navbar-items .buttton{
    padding-right: 20px;
}