
 ._2fch{
    display: flex;
    flex-direction: column;
 }
 ._6ivr  .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
    color: #F9C623 !important;
  }
 ._6ivr  .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active{
    color: #F9C623 !important ;
  }
 ._6ivr  .saveandproceed{
    background-color: #F9C623 !important ;
    color: black !important;
    padding-left: 30px !important;  
    padding-right: 30px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    border-radius: 10px !important;
  }
 ._6ivr  .previous{
    border: 1px solid rgb(0, 0, 0) !important;
    color: rgb(0, 0, 0) !important;
    padding-left: 30px !important;  
    padding-right: 30px !important;
    border-radius: 10px !important;
    margin-right: 10px !important;
  
  }
 ._6ivr  .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    color: #ffff !important;
  }
 ._6ivr  .css-117w1su-MuiStepIcon-text {
    fill: #000000 !important;
  }
  
 ._6ivr  .css-19midj6 {
    background-color: #ffffff !important;
    border-radius: 20px;
  }
 ._6ivr  .leftcolor{
    background-color: #F4F5F6;
  }
 ._6ivr  .rightcolor{
    background-color: #eeeeee;
    padding: 40px;
    
  }
 ._6ivr  .tables_div{
    width: 100%;
    margin-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
  }
 ._6ivr  .title{
    margin: 20px;
    font-size: 15px;
    font-weight: 600;
  }
 ._6ivr  .addbanquet{
    width: 200px!important;
    background-color:  #F9C623 !important ;
  }
 ._6ivr  .css-187mznn-MuiSlider-root {
    color: black !important;
    height: 8px !important;
  }
 ._6ivr  .stepper-logo{
  
    padding-left: 30PX;
    height: 70px ;
    padding-top: 10px !important;
   
  }
 ._6ivr  .stepper-logo img{
    height: 80% !important;
  }
 ._6ivr  .stepper-header{
    background-color: #15101F !important;
    display: flex;
    justify-content: space-between;
    align-items:  center;
  }
 ._6ivr  .departmentttt{
    width: 40% !important;
    margin-top: 20px !important;
  }
 ._6ivr  .department-button{
   width: 40%;
   
  }
 ._6ivr  .department-button button{
    background-color: #F9C623 !important;
  }
 ._6ivr  .sendinvitation{
    background-color: #F9C623  !important;
  }
 ._6ivr  .MuiCheckbox-colorPrimary.Mui-checked {
    color:  #F9C623 !important;
  }
 ._6ivr  .managebanquet{
    margin-bottom: 20px !important;
  }
  
  
 ._6ivr  .css-9tmuzo-MuiStepper-root{
    padding: 30px !important;
  }
 ._6ivr  ._0yet {
    position: relative;
  }
  
 ._6ivr  ._0yet .cabresto-baa {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(128, 128, 128, 0.552);
    z-index: 3;
  }
  
 ._6ivr  ._0yet .cabresto-baa .corsair-bomb {
    display: flex;
    justify-content: center;
  }
  
 ._6ivr  ._0yet .cabresto-baa .corsair-bomb .incenter-kava {
    background-color: white;
  }
  
 ._6ivr  ._0yet .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
 ._6ivr  ._0yet ._2sma {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-weight: 500;
  }
  
 ._6ivr  ._0yet ._2sma .header {
    font-size: 20px;
    margin-top: 10px;
  }
  
 ._6ivr  ._0yet ._2sma .corsair-bomb {
    display: flex;
    gap: 40px;
    margin-bottom: 40px;
    margin-top: 20px;
  }
  
 ._6ivr  ._0yet ._2sma .corsair-bomb .peels-dive {
    width: 50vw;
    margin: auto;
    padding: 30px 40px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    margin-top: 20px;
  }
  
 ._6ivr  ._0yet ._2sma .corsair-bomb .peels-dive .eschar-link {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  
 ._6ivr  ._0yet ._2sma .corsair-bomb .peels-dive > button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgb(25, 118, 210);
    width: fit-content;
    padding: 12px 15px;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
  }
  
 ._6ivr  ._0yet ._2sma .corsair-bomb .incenter-kava {
    padding: 30px 40px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
  }
  
 ._6ivr  ._0yet ._2sma .incenter-kava {
    display: flex;
    flex-direction: column;
  }
  
 ._6ivr  ._0yet ._2sma .incenter-kava input {
    width: 350px;
    outline: none;
    height: 40px;
    border-radius: 20px;
    border: 1px solid grey;
    font-size: 16px;
    padding-left: 15px;
    margin-top: 10px;
  }
  
 ._6ivr  ._0yet ._2sma .incenter-kava p {
    font-size: 14px;
    color: rgb(62, 62, 62);
  }
  
 ._6ivr  ._0yet ._2sma .incenter-kava button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgb(25, 118, 210);
    width: fit-content;
    padding: 12px 15px;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
  }
  
 ._6ivr  ._0yet .btn{
    all: unset;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgb(25, 118, 210);
    width: fit-content;
    padding: 12px 15px;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
    margin-bottom: 30px;
  }
  
 ._6ivr  ._0yet ._2sma .cloth-rue {
    margin-top: 30px;
  }
  
 ._6ivr  ._0yet ._2sma .cloth-rue p {
    font-size: 14px;
    color: rgb(62, 62, 62);
  }
  
 ._6ivr  ._0yet ._2sma .cloth-rue button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgb(25, 118, 210);
    width: fit-content;
    padding: 12px 15px;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
    margin-bottom: 30px;
  }
 ._6ivr  ._0yet .btn{
    all: unset;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgb(25, 118, 210);
    width: fit-content;
    padding: 12px 15px;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
    margin-bottom: 30px;
  }
  
 ._6ivr  ._0yet ._2sma .cloth-rue .clicking-lob {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: rgb(25, 118, 210);
    padding: 5px 15px;
    font-weight: 700;
    font-size: 30px;
    color: white;
    border-radius: 20px;
    cursor: pointer;
  }
  
 ._6ivr  ._0yet ._2sma .cloth-rue .clicking-lob span {
    transform: translate(0.5px, 2px);
  }
  
 ._6ivr  ._0yet ._2sma .bologna-hip {
    display: flex;
    margin-top: 10px;
  }
  
 ._6ivr  ._0yet ._2sma .bologna-hip li {
    list-style: none;
  }
  
 ._6ivr  ._0yet ._2sma .cloth-rue .clicking-lob {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: rgb(25, 118, 210);
    padding: 5px 15px;
    font-weight: 700;
    font-size: 30px;
    color: white;
    border-radius: 20px;
    cursor: pointer;
  }
  
 ._6ivr  ._0yet ._2sma .cloth-rue .clicking-lob span {
    transform: translate(0.5px, 2px);
  }
  
 ._6ivr  ._0yet ._2sma .bologna-hip {
    display: flex;
    margin-top: 10px;
  }
  
 ._6ivr  ._0yet ._2sma .bologna-hip li {
    list-style: none;
  }
  
 ._6ivr  ._0yet ._2sma .amelcorn-dors {
    border: 1px solid grey;
    border-top: 6px solid grey;
    border-bottom: none;
  }
  
 ._6ivr  ._0yet ._2sma .amelcorn-dors .box {
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid grey;
  }
  
 ._6ivr  ._0yet ._2sma .amelcorn-dors .box span:first-of-type {
    width: 300px;
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid grey;
    color: rgb(74, 74, 74);
  }
  
 ._6ivr  ._0yet ._2sma .amelcorn-dors .box span {
    padding-left: 20px;
    font-family: 'Roboto', sans-serif;
  }
  
 ._6ivr  ._0yet ._2sma .amelcorn-dors .box span:nth-of-type(2) {
    font-weight: 400;
  }
 ._6ivr  .tables_div{
    width: 100%;
    margin-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
  }
 ._6ivr  .some_title{
    margin: 20px;
    font-size: 15px;
    font-weight: 600;
  }
  
 ._6ivr  ._0yet ._2sma .third-dox {
    margin-bottom: 20px;
  }
  
 ._6ivr  ._0yet ._2sma .third-dox h2 {
    color: rgb(64, 64, 64);
  }
  
 ._6ivr  ._0yet ._2sma .rifer-hoe {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: auto;
    margin-top: 20px;
  }
  
 ._6ivr  ._0yet ._2sma .rifer-hoe span {
    font-family: 'Roboto', sans-serif;
    margin: 0px;
    padding: 0px;
    font-weight: 400;
    font-size: 14px;
    color: rgb(25, 118, 210);
    cursor: pointer;
  }
  
 ._6ivr  ._0yet ._2sma .rifer-hoe button {
    transform: translateY(5px);
  }
  
 ._6ivr  ._0yet .chyme-jets {
    width: 100%;
    margin-top: 30px;
    font-family: 'Roboto', sans-serif;
  }
  
 ._6ivr  ._0yet .chyme-jets > span {
    font-weight: 700;
  }
  
 ._6ivr  ._0yet .chyme-jets > div {
    margin-top: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  
  ._0yet .chyme-jets input,
 ._6ivr  ._0yet .chyme-jets label {
    cursor: pointer;
  }
  
 ._6ivr  ._0yet .edit {
    padding: 2px 8px;
    border-radius: 5px;
    color: white;
    background-color: green;
  }
  
 ._6ivr  ._0yet .view {
    padding: 2px 8px;
    border-radius: 5px;
    color: white;
    background-color: grey;
  }
  
 ._6ivr  ._0yet .none {
    padding: 2px 8px;
    border-radius: 5px;
    color: white;
    background-color: rgb(219, 93, 93);
  }
  
 ._6ivr  ._0yet .css-1hv8oq8-MuiStepLabel-label {
    white-space: nowrap;
  }
  
 ._6ivr  ._0yet .css-wn4ipv {
    width: 80vw;
  }
  
 ._6ivr  ._0yet ._2sma .robust-did {
    width: 60vw;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 40px 50px;
    border-radius: 10px;
  }
 ._6ivr  .MuiButton-contained:hover {
    background-color: #F9C623 !important;
  }
 ._6ivr  .css-480o17-MuiGrid-root {
    margin-top:0px !important;
     width: 100% !important; 
    margin-left: 0px !important;
  }
  
 ._6ivr  .css-480o17-MuiGrid-root>.MuiGrid-item {
    padding-left: 32px !important;
    padding-right: 32px !important;
}
._6ktd{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}