.app-layout-sidebar{
    &__menu{
        padding:20px 0;
        margin:0;
        display: flex;
        flex-direction: column;
        list-style: none;     
        &__item{
            .sidebar-btn.MuiButtonBase-root{
                font-family:'Rubik' ;
                padding:20px 0;
                width: 100%;
                justify-content: flex-start;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #252525;
                text-transform: capitalize;
                border-radius: 0;
                white-space: nowrap;
            }
        }
    }
    &__accordion{
        .MuiButtonBase-root.MuiAccordionSummary-root{
            margin:0;
            min-height: unset !important;
            padding:0;
        }
        .MuiPaper-root{
            box-shadow: none;
        }
        .MuiAccordionSummary-content{
            margin:0 !important;
        }
        .MuiAccordionDetails-root{
            padding:0;
        }
        &__list{
            padding:0;
            margin:0;
            display: flex;
            flex-direction: column;
            list-style: none;
            .sidebar-btn.MuiButtonBase-root{
                padding: 10px 20px 10px 30px;
                font-weight: 400;
            }
        }
    }
}
.sidebar-dropdown .MuiAccordionSummary-content{
    display: flex;
    align-items: center;
}
.sidebar-dropdown .MuiAccordionSummary-content svg{
    font-size: 30px;

}
.app-layout-sidebar{
    padding: 0 15px;
}
