._3ceu .app-card__content {
    margin: 0px;
    padding: 0px;
}

._3ceu .app-card {
    padding: 30px;
}

._3ceu .app-card__title {
    margin-bottom: 40px;
}

._0sci .app-card__content {
    margin: 0px;
    padding: 0px;
}

._0sci .app-card__title {
    margin-bottom: 30px;
}

._8abi .app-card__content {
    margin: 0px;
    padding: 0px;
}

._8abi .app-card {
    padding: 20px;
}

._8abi .app-card__title {
    padding-left: 20px;
}

._8abi .css-1lj5egr-MuiGrid-root {
    margin: 0px;
    padding: 0px;
}

._8abi .css-11lq3yg-MuiGrid-root {
    max-height: 70px;
}

._6tmr .app-card__content {
    margin: 0px;
    padding: 0px;
}

._6tmr .app-card__title {
    margin-bottom: 25px;
}

._3qtm {
    margin: 0px;
    padding: 0px;
    font-family: "Roboto";
    margin-top: 40px;
    padding-left: 20px;
    width: fit-content;
}

._3qtm .container span {
    width: 200px;
    display: block;
}

._3qtm .container .table-header {
    background-color: #F9C623;
    padding: 10px;
    display: flex;
}

._3qtm .container .body div {
    display: flex;
    height: 40px;
    align-items: center;
}

._3qtm .triazine-cob {
    margin-top: 20px;
    color: grey;
}

._3qtm .container .body input {
    height: 40px;
    border: none;
    outline: none;
    border-bottom: 1px solid rgb(201, 201, 201);
}

.MuiFormControl-root .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 4px 0 5px !important;
}

.css-apqrd9-MuiTableBody-root .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    width: 50px;
}

.cardDiv {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
    margin-bottom: 10px;
}

.MuiTableContainer-root .css-y8ay40-MuiTableCell-root {
    text-wrap: nowrap !important;
}

.MuiTablePagination-toolbar {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
}

.MuiTablePagination-root {
    justify-content: end;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Objectivity';
    font-weight: 700;
    font-size: 1rem;
}

._3lqy {
    padding: 20px 20px !important;
}

._4fnr {
    width: 130px !important;
    margin-left: -13px !important;
}

.payment_no_border_bottom input{
    border: none !important;
}
.payment_no_border_bottom {
    margin-right: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
