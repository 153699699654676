.app-card {
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 40px;

    &__title {
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #a48901;
        padding: 0;
        margin-bottom: 15px;
    }

    &__content {
        margin-top: 20px;
        width: 100%;
        padding: 30px;
    }
}

.app-card-550 {
    max-width: 550px;
}