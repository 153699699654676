._3iuh.MuiContainer-root {
    box-shadow: none;
    margin-bottom: 20px;
}

._3iuh .MuiBox-root-18 {
    margin-top: 0px;
}

._3iuh ._5jsm {
    display: flex;
    gap: 50px;
    justify-content: start;
    margin-top: 20px;
}

._3iuh .tumour-sew {
    font-size: 14px;
    color: rgb(54, 54, 54);
}
