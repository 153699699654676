.ChangePass_div{
    display: flex;
    height: calc(100vh - 84px);
    justify-content: center;
    align-items: center;
    gap: 3rem;
}
.ChangeBox_div{
    width: 400px;
    padding: 10px;
    margin-top: 50px;
}
.ChangeBox_div h1{
    font-family: 'Objectivity';
    font-size: 35px;
    font-weight: 700;
    color: #21A2DB
}
.ChangeBox_div p{
    font-size: 10px;
    font-weight: 500;
    padding-bottom: 10px;
    color: gray
}
.image_div{
    padding-top: 50px;
}
.Changefield_div{
    display: flex;
    flex-direction: column;
}
.Changefield_div input{
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid gray;
    outline: none;
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    
}
.Changefield_div input:focus{
    border: 1px solid #21A2DB;
  }

.Changefield_div .btn-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: none;
    background: #21A2DB;
    color: white;
    cursor: pointer;
}
.btn-link{
    display: flex;
    justify-content: center;
}
.btn-link a{
    text-decoration: none;
    color: #21A2DB;
    font-size: 10px;
    font-weight: 700;
    padding: 20px;
}
.div_field{
    display: flex;
    justify-content: center;
    align-items: center;
}
.iconeye{
    border: none;
    margin-left: -35px;
    background: white;
    font-size: 15px;
    color: gray;
}
.btn-btn .css-18lrjg1-MuiCircularProgress-root{
    color: white;
}
