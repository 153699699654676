*{
    box-sizing: border-box;
}
body{
    font-family: 'Objectivity';
    font-weight: 400;
    font-size: 14px;
    color: #412727;
}
.MuiAccordionSummary-content{
    a{
        width: 100%;
    }
}
.d-flex{
    display: flex;
}
.justify-content-between{
    justify-content: space-between;
}
// .justify-content-center{
//     justify-content: center;
// }
.justify-content-end{
    justify-content: flex-end;
}
.align-items-center{
    align-items: center
}
.w-full{
    width: 100%;
}
.mt-5{
    margin-top: 5px !important;
}
.mt-10{
    margin-top: 10px !important;
}
.mt-15{
    margin-top: 15px !important;
}
.mt-20{
    margin-top: 20px !important;
}

.ml-15{
    margin-left: 20px;
}
.mr-15{
    margin-right: 20px;
}
.app-full-page{
    width: 100%;
    height: 100vh;
    background: #F8FAFC;
    position: relative;
}
.app-page-wrapper{
    width: 100%;
    height: calc(100vh - 70px);
    display: flex;
}
.app-page-sidebar{
    .MuiDrawer-paper{
        background-color: #ffffff;
        top:70px;
        height: calc(100% - 70px);
    }
}
.app-main{
    width: 100%;
    overflow: hidden;
    background: #F8FAFC;
    padding: 0 !important;
}
.app-page-content{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    padding: 24px;
}
.app-page-scroll{
    height: calc(100vh - 70px);
    width: 100%;
}
.app-btn{
    &.MuiButtonBase-root{
        font-family: 'Objectivity';
        padding: 6px 22px;
        border-radius: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        text-transform: capitalize;
        margin-left: 10px;
    }
    &-primary{
        &.MuiButtonBase-root{
            background: #F9C623;
            &:hover{
                background: #e7b613;
            }
        }
    }
}

.tree-bg{
    
}
.app-tree-bg{
    position: fixed;
    bottom: 0;
    right:0;
    height: 300px;
    filter: grayscale(1);
    user-select: none;
    pointer-events: none;
    z-index: 0;
}
.app-form-field em{
    font-family: 'Objectivity';
    font-style: normal;
    font-weight: 500;
}
.app-new-app-card__button{
    display: flex;
    justify-content: flex-start;
}
.app-component-table .MuiTableCell-head{
    font-family: 'Objectivity';
    font-style: normal;
    font-weight: 700;
    background-color: antiquewhite;
}
.app-component-table .MuiTableCell-body{
    font-family: 'Objectivity';
    font-style: normal;
    font-weight: 500;
}
@import './variable';
@import './layouts/layouts.scss';
@import './components/components.scss';
@import './pages/pages.scss';

.phone-number-input input[type=number]::-webkit-inner-spin-button, 
.phone-number-input input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.phone-number-input input{
    padding: 0px !important;
}